import React from 'react';

import Routes from './routes';
// import ScrollToTop from './config/ScrollToTop';
// import {GlobalStyle} from './assets/styles/GlobalStyle';

function App() {
  return (
    <>
      {/* <ScrollToTop /> */}
      <Routes/>
      {/* <GlobalStyle /> */}
    </>
  );
}

export default App;
